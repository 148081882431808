import React from 'react';
import HereMarker from 'ref-client-core/views/shared/hereMap/HereMarker';

// const getPower = (power) => `${power / 1000} kW`;

function NearByMarker({
  setResultState,
  dataId,
  icon,
  position,
}) {

  const selectStation = (e) => {
    setResultState({
      selectedNearBy: dataId
    });
  };

  const marker =
    <HereMarker
      latLng={{ lat: position.lat, lng: position.lng }}
      options={{ icon }}
      onTap={selectStation}
    />;

  return (
    <>
      {marker}
    </>
  );
}

export default NearByMarker;
