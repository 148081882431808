const chargingStationsConfig = {
  title: 'Charging stations',
  tabTitle: 'Stations',
  settings: {
    'Api Key': {
      type: 'credentials',
      apiKey: process.env.REACT_APP_APIKEY,
    },
    'Api Key 2': {
      type: 'credentials',
      apiKey: process.env.REACT_APP_APIKEY2,
    },
  },
  hideUrlParams: true,
  inputs: [
    {
      type: 'select',
      options: {
        key: 'evStation[minPower]',
        label: 'Minimum Power',
        selectOptions: [
          {
            label: '10+ kW',
            value: '10'
          },
          {
            label: '50+ kW',
            value: '50'
          },
          {
            label: '100+ kW',
            value: '100'
          },
          {
            label: '150+ kW',
            value: '150'
          }
        ]
      }
    },
    {
      type: 'input',
      options: {
        key: 'evStation[connectorTypeIds]',
        label: 'connector type IDs',
        placeholder: 'connector type IDs',
        tooltip:  'Accepted are comma-separated integer values. Connector Ids used in routing:\n 29: JEVS G 105 (CHAdeMO)\n 33: IEC 62196-3 type 1 combo (SAE J1772), commonly known as CCS1\n 34: IEC 62196-3 type 2 combo (Mennekes), commonly known as CCS2\n 43: Tesla Connector, commonly known as North American Charging Standard (NACS)\n 53: GB/T (Chinese) DC connector'
      }
    },
    {
      type: 'input',
      options: {
        key: 'evStation[eMobilityServiceProviderPartnerIds]',
        label: 'e-Mobility Service Provider Partner IDs',
        placeholder: 'e-Mobility Service Provider Partner IDs'
      }
    },
    {
      type: 'input',
      options: {
        key: 'evStation[supplierNames]',
        label: 'Supplier Names',
        placeholder: 'Supplier Names',
        tooltip: 'Sets a constraint on the charge point operator name operating of the EV station place result.\nAccepted are multiple comma-separated names'
      }
    },
    {
      type: 'select',
      options: {
        key: 'evStation[current]',
        label: 'Current',
        selectOptions: [
          {
            label: 'AC',
            value: 'AC'
          },
          {
            label: 'DC',
            value: 'DC'
          }
        ]
      }
    },
    {
      type: 'chargingStations'
    },
    {
      type: 'loader'
    }
  ],
  map: [
    {
      type: 'chargingStations'
    },
    {
      type: 'group',
      options: {
        conditions: [
          { type: 'equals', key: 'fields.showBbox', value: true },
          { type: 'equals', key: 'fields.inputType', value: 'Bounding Box' }
        ],
        children: [
          {
            type: 'rectangleList',
            options: {
              key: 'bbox',
              noContextMenu: true,
              path: 'fields.bbox',
              draggable: true,
              resizable: true
            }
          }
        ]
      }
    },    
    {
      type: 'markers',
      options: {
        format: 'searchAtMarkers'
      }
    },
    {
      type: 'contextMenu',
      options: {
        items: [
          {
            type: 'setFields',
            options: {
              text: 'Add Bounding Box',
              key: 'bbox',
              setFieldsFormatter: 'addChargingStatioinsBBox',
              conditions: [
                {
                  type: 'equals',
                  key: 'fields.inputType',
                  value: 'Bounding Box'
                }
              ]
            }
          },
          {
            type: 'copyLatLon',
            options: {
              text: 'Copy LatLon'
            }
          },
          {
            type: 'copyAddress',
            options: {
              text: 'Copy Address'
            }
          },
          {
            type: 'inspectLinkMenuItem',
            options: {
              text: 'Inspect Segment'
            }
          }
        ]
      }
    }
  ],
  resultPanel: [
    {
      type: 'tab',
      options: {
        tabs: [
          {
            title: 'Charging Station',
            type: 'chargingStations'
          },
          {
            title: 'Nearby',
            type: 'chargingStationsNearby'
          }
        ]
      }
    }
  ],
  autoRefresh: {
    conditions: [
      {
        type: 'never'
      }
    ]
  }
};

export default chargingStationsConfig;
