import React from 'react';

class MatrixResult extends React.Component {
  componentDidMount() {
    this.getStatus();
  }

  getStatus() {
    const {
      fields: { matrixResultStatusUrl },
      setFields,
      settings,
      setResultState,
      request,
    } = this.props;

    if (matrixResultStatusUrl) {
      fetch(`${matrixResultStatusUrl}?apiKey=${settings.apiKey}`, {
        redirect: 'manual',
      }).then((res) => {
        if (res.status === 0 && res.type === 'opaqueredirect') {
          setFields({
            matrixResultUrl: matrixResultStatusUrl.replace('/status', ''),
            matrixResultStatusUrl: null,
          });
          setResultState({
            isMatrixLoading: false,
          });
          clearTimeout(this.timeoutId);
          request();
        } else {
          setResultState({
            isResultPanelShown: true,
            isMatrixLoading: true,
          });
          this.timeoutId = setTimeout(this.getStatus.bind(this), 5000);
        }
      });
    }
  }

  render() {
    return null;
  }
}

const matrixResultInputPlugin = {
  parseUrl: () => ({}),
  defaultState: () => ({}),
  getRequestOptions: () => ({}),
  Component: MatrixResult,
};

export default matrixResultInputPlugin;
