import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get, isEqual } from 'lodash';
import { searchNearby } from './helpers';
import { Tree } from '@here/ref-client-ui';
import ResultPanelErrors from '../core/views/resultPanel/ResultPanelErrors';

class ChargingStationsResultNearby extends React.Component {
  async componentDidMount() {
    const { setResultState } = this.props;
    const cs = get(this.props, 'result.state.selectedStation');
    if (cs) {
      const restaurants = await searchNearby(cs.position, this.props.settings);
      setResultState({nearBys: restaurants})
    }
    setResultState({nearBy: true});
  }

  async componentDidUpdate(prevProps) {
    const { setResultState } = this.props;
    const cs = get(this.props, 'result.state.selectedStation');
    if (cs && !isEqual(cs, get(prevProps, 'result.state.selectedStation'))) {
      const restaurants = await searchNearby(cs.position, this.props.settings);
      setResultState({nearBys: restaurants})
    }
  }

  onClick(id, e) {
    if ( e.target.className === 'rf-charging-stations__content') {
      const { setResultState, result = {} } = this.props;
      const { state = {} } = result;
      const { selectedNearBy } = state;
      setResultState({selectedNearBy: id === selectedNearBy ? null : id});
    }
  }

  getDetails(nearByRest) {
    return <Tree data={nearByRest}/>
  }

  render(){
    const { result = {} } = this.props;
    const { state = {} } = result;
    const { selectedStation, error, nearBys = [], selectedNearBy } = state;

    if (error) {
      return <ResultPanelErrors response={error} />;
    }

    if (!selectedStation) {
      return <h4>Please select charging station</h4>;
    }

    const nearBysEl = nearBys.map((nearByRest, index) => {
      const isSelected = selectedNearBy === nearByRest.id;
      const listItemClasses = classnames('rf-charging-stations', {
        'rf-charging-stations_selected': isSelected,
      });
      return (
        <li
          className={listItemClasses}
          onClick={this.onClick.bind(this, nearByRest.id)}
          key={`${nearByRest.id}${index}`}
        >
          <div className="rf-charging-stations__inner">
            <div className="rf-charging-stations__content">{(index+1)+". "+nearByRest.title}</div>
            {isSelected && this.getDetails(nearByRest)}
          </div>
        </li>
      );
    })

    return (
      <>
        <ul>{nearBysEl}</ul>
      </>
    );
  }
}


function mapStateToProps(state) {
  return {
    selectedTab: state.selectedTab
  };
}

const mapDispatchToProps = {
};

ChargingStationsResultNearby.propTypes = {
  result: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChargingStationsResultNearby);
