import React from 'react';
import PropTypes from 'prop-types';
import {
  CheckboxList,
  ExpandFormRow,
  Loader,
  TabsControl,
} from '@here/ref-client-ui';
import { get, set } from 'lodash';
import yamlLoad from './yamlLoad';
import mapAttributesLoad from './mapAttributesLoad';
import { getOpenapiInfo, getOpenapiUrl } from '../openapiHelpers';
import Searchable from '../../../views/shared/searchUtils/Searchable';
import './docLink.scss';

const Tab = {
  ALL_EXCEPT: 'ALL_EXCEPT',
  SELECTED: 'SELECTED',
};

class CheckBoxesFormView extends React.Component {
  state = {
    params: [],
    isLoading: false,
  };

  componentDidMount() {
    const {
      options: { openapiInfo },
      settings,
      fields: { customizations }
    } = this.props;

    if (openapiInfo) {
      const { urlPath, urlRegex, urlSuffix, keys } = openapiInfo;
      const propsUrl = get(this.props, urlPath);
      const url = getOpenapiUrl(propsUrl, urlRegex, urlSuffix);
      getOpenapiInfo(url, keys, customizations, settings).then((openapiInfo) => {
        if (!this.isUnmounted) {
          this.setState({ openapiInfo });
        }
      });
    }

    this.loadParams();
  }

  componentDidUpdate(prevProps) {
    const {
      options: { loadParams },
    } = this.props;
    if (loadParams) {
      const { urlPath } = loadParams;
      const customizations = get(this.props, 'fields.customizations');
      if (get(this.props, urlPath) !== get(prevProps, urlPath) 
          || customizations !== get(prevProps, 'fields.customizations')
          || (customizations && get(this.props, 'settings.apiKey') !== get(prevProps, 'settings.apiKey'))
          || (customizations && get(this.props, 'settings.token') !== get(prevProps, 'settings.token'))
        ) {
        this.loadParams();
      }
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  loadParams = () => {
    const {
      options: { loadParams },
    } = this.props;
    if (loadParams) {
      const { url, resultPath, urlPath, urlApiKey, urlRegex, urlSuffix, mapAttributes, excludes, notSupported } = loadParams;

      let loadUrl;
      if (url) {
        loadUrl = url;
      } else {
        const propsUrl = get(this.props, urlPath, '');
        const regexp = new RegExp(urlRegex);
        const matchedUrl = propsUrl.match(regexp);
        if (matchedUrl) {
          loadUrl = `${matchedUrl[0]}${urlSuffix}`;
        }
      }
      if (!loadUrl) {
        return;
      }
      this.setState({ isLoading: true, params: [] });
      if (mapAttributes) {
        const propsUrlApiKey = get(this.props, urlApiKey, window.APIKEY);
        mapAttributesLoad(`${loadUrl}?apiKey=${propsUrlApiKey}`).then((data) => {
          if (this.isUnmounted) {
            return;
          }
          const params = data.filter(layer=>{
            const { value } = layer;
            const layerAttr = value.substring(0, value.length-3);
            
            return (excludes.indexOf(layerAttr) > -1 || notSupported.indexOf(layerAttr) > -1 )? false : true;
          })
          this.setState({ isLoading: false, params: params });
        });
      } else {
        const { settings, fields: { customizations } } = this.props;
        yamlLoad(loadUrl, customizations, settings).then((data) => {
          if (this.isUnmounted) {
            return;
          }
          let params = get(data, resultPath).filter(
            (value) => !value.startsWith('-')
          );
          params = params.map((value) => ({ value, label: value }));
          this.setState({ isLoading: false, params });
        });
      }
    }
  };

  onChange = (e, value) => {
    const {
      setFields,
      fields,
      options: { key },
    } = this.props;
    let attrList = fields[key] && fields[key].values ? fields[key].values : [];
    const { checked } = e.target;
    const valueIndex = attrList.indexOf(value);

    if (checked) {
      if (attrList.indexOf(value) === -1) {
        setFields({
          [key]: {
            tab: fields[key].tab,
            values: [...attrList, value],
          },
        });
      }
    } else if (attrList.indexOf(value) !== -1) {
      setFields({
        [key]: {
          tab: fields[key].tab,
          values: [
            ...attrList.slice(0, valueIndex),
            ...attrList.slice(valueIndex + 1),
          ],
        },
      });
    }
  };

  onTabChange = (tab) => {
    const {
      setFields,
      fields,
      options: { key },
    } = this.props;
    setFields({
      [key]: {
        values: fields[key].values,
        tab,
      },
    });
  };

  renderTabs = () => {
    const { fields, options } = this.props;
    if (!options.supportExcludes) return null;

    let tabsData = [
      { label: 'Selected', name: Tab.SELECTED },
      { label: 'All Except', name: Tab.ALL_EXCEPT },
    ];
    let currentTab = fields[options.key].tab;

    return (
      <TabsControl
        currentTabName={currentTab}
        onChange={this.onTabChange}
        tabsData={tabsData}
      />
    );
  };

  getTooltip() {
    const {
      options: { tooltip },
    } = this.props;
    let tooltipEl = null;
    const { openapiInfo } = this.state;
    if (tooltip || openapiInfo) {
      tooltipEl = (
        <div className="rf-form-view__tooltip">{tooltip || openapiInfo}</div>
      );
    }

    return tooltipEl;
  }

  render() {
    const { options, fields } = this.props;
    const {
      key,
      params: configParams,
      label,
      isExpanded,
      cssClasses = '',
      loadParams,
      labelMapping = [],
    } = options;
    const { params: stateParams, isLoading } = this.state;
    let secondText = false;
    let secondUrl = '';
    if (loadParams) {
      const { urlPath, urlApiKey, urlRegex, secUrlSuffix, secText, url } = loadParams;
      if ( secText ) {
        const propsUrl = urlPath ? get(this.props, urlPath, '') : url;
        const propsUrlApiKey = get(this.props, urlApiKey, window.APIKEY);
        const regexp = new RegExp(urlRegex);
        const matchedUrl = propsUrl.match(regexp);
        if (matchedUrl) {
          secondUrl = `${matchedUrl[0]}${secUrlSuffix}?apiKey=${propsUrlApiKey}`;
        }
        secondText = secText;
      }
    }


    let attrList = fields[key] && fields[key].values ? fields[key].values : [];

    let params = loadParams ? stateParams : configParams;
    const checkBoxParams = params
      .map((param) => {
        const mapping = labelMapping.find(m => m[param.label]);

        return mapping ? {
          ...param,
          label: mapping[param.label]
        } : param
      })
      .filter((param) => {
        if (!param.condition) {
          return true;
        }
        const {
          condition: { type, key, value },
        } = param;
        switch (type) {
          case 'equals':
            return fields[key] === value;
          default:
            throw new Error(`Unsupported condition ${type}`);
        }
      })
      .map((param) => ({
        ...param,
        isChecked: attrList.indexOf(param.value) !== -1,
        onChange: this.onChange,
      }));

    const unknownParams = attrList
      .filter((attr) => !params.find((param) => param.value === attr))
      .map((attr) => ({
        value: attr,
        label: attr,
        isUnknown: true,
        isChecked: true,
        onChange: this.onChange,
      }));

    return (
      <Searchable searchKey={key}>
        <div className="rf-expand-form-row-container">
          <ExpandFormRow
            label={label}
            isExpanded={!!isExpanded}
            className={cssClasses}
            tooltip={this.getTooltip()}
            tooltipPlacement="right"
          >
            {secondText && <a className='rf-doc-link rf-input__caption' href={secondUrl} target="_blank" rel="noopener noreferrer">{secondText}</a>}
            {this.renderTabs()}
            <CheckboxList params={[...checkBoxParams, ...unknownParams]} />
            {isLoading && <Loader />}
          </ExpandFormRow>
        </div>
      </Searchable>
    );
  }
}

CheckBoxesFormView.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  setFields: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: ({ parsedParams, options, postData = {} }) => {
    const { key, isPost, defaultTab } = options;
    const keyLowerCase = key.toLowerCase();

    let values;
    let tab = defaultTab || Tab.SELECTED;
    if (isPost) {
      values = get(postData, key) || [];
    } else {
      values = parsedParams[keyLowerCase]
        ? parsedParams[keyLowerCase].split(',')
        : [];
      delete parsedParams[keyLowerCase];
    }
    values = values.map((value) => {
      if (value.startsWith('-')) {
        tab = Tab.ALL_EXCEPT;
        return value.substring(1);
      }
      return value;
    });

    return { [key]: { values, tab } };
  },

  defaultState: (options) => {
    let tab = options.defaultTab || Tab.SELECTED;
    let values = options.initState ? options.initState.split(',') : [];
    values = values.map((value) => {
      if (value.startsWith('-')) {
        return value.substring(1);
      }
      return value;
    });
    return { [options.key]: { values, tab } };
  },

  getRequestOptions: (fields, options) => {
    const { key, isPost } = options;
    let values = fields[key] ? fields[key].values : [];

    if (values.length === 0) return {};

    if (fields[key].tab === Tab.ALL_EXCEPT) {
      values = values.map((value) => `-${value}`);
    }

    if (isPost) {
      const data = {};
      set(data, key, values);
      return {
        method: 'post',
        data,
      };
    }

    return { params: { [key]: values.join(',') } };
  },
  Component: CheckBoxesFormView,
};
