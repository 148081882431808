import axios from 'axios';
import yaml from 'js-yaml';

const CACHE = {};

const inProgress = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (url, customization, settings) {
  const options = { responseType: 'text' };
  if (customization) {
    url = `${url}?customizations=${customization}`;
    if (settings.type === 'apiKey') {
      url = `${url}&apiKey=${settings.apiKey}`;
    } else if (settings.type === 'hereToken') {
      options.headers = {
        'AUthorization': `Bearer ${settings.token}`
      }
    }
  }

  if (inProgress[url]) {
    return inProgress[url];
  }
  const promise = new Promise((resolve, reject) => {
    if (CACHE[url]) {
      resolve(CACHE[url]);
      delete inProgress[url];
      return;
    }

    try {
      axios
        .get(url, options)
        .then((res) => {
          const { data } = res;
          const yamlData = yaml.load(data);
          CACHE[url] = yamlData;
          resolve(yamlData);
          delete inProgress[url];
        })
        .catch((err) => {
          reject(err);
        });  
    } catch(e) {
    }
  });
  inProgress[url] = promise;

  return promise;
}
