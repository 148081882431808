import * as NotificationActions from 'state/notification/actions';

import fetch from 'utils/fetch';
import { RESPONSE_TIMEOUT } from 'config/main';
import { addHistory } from '../history/actions';
import { setIsLoading } from '../isLoading/actions';
import { getID } from '../id';
import { getTabIndexById } from 'state/tabs';
import { getTabSettings, getTabPreset } from '../preset/reducer';
import settingsPlugin from '../../../../core/settingsPlugin';
import queryParamsSelector from '../../../selectors/queryParamsSelector';
import { getFields } from '../fields/reducer';
import formViewsPlugin from '../../../../core/formViewsPlugin';
import { setRawResult } from '../result/raw/reducer';
import { getCurrentConfig } from '../../../config';
import { getTitle } from '../title';
import { setSettingsValue } from '../../../settings';
import { merge } from 'lodash';
import splunkLog from '../../../../utils/splunkLog';
import refClient from '../../../../core/refClient';

function timeoutNotifier(dispatch, getState) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (getState().isLoading) {
        dispatch(
          NotificationActions.set({
            message:
              'Server response time is slow. Do you want to cancel the request?',
            impact: 'significant',
            action: {
              label: 'Cancel',
              callback: () => {
                if (getState().isLoading) {
                  dispatch(setIsLoading(false));
                  reject();
                }
              },
            },
          })
        );
      }
    }, RESPONSE_TIMEOUT);
  });
}

export function fetchData(tabIndex, reqOpts = {}) {
  return async (dispatch, getState) => {
    dispatch(setIsLoading(true, tabIndex));
    const state = getState();
    const tabId = getID(state, tabIndex);
    const title = getTitle(state, tabIndex);

    const formFields = getFields(state, tabIndex);
    const config = getCurrentConfig(state, tabIndex);

    const fieldsRequestOptions = config.inputs.reduce((acc, input) => {
      try {
        const requestOptions = formViewsPlugin
          .get(input.type)
          .getRequestOptions(formFields, input.options, refClient);
        return merge(acc, requestOptions);
      } catch (e) {
        return acc;
      }
    }, {});

    const settings = getTabSettings(state, tabIndex);
    const { getRequestOptions, getDisplayUrl, preFetch } = settingsPlugin.get(
      settings.type
    );

    const settingsRequestOptions = getRequestOptions(
      settings,
      queryParamsSelector(state, tabIndex)
    );
    let displayUrl = getDisplayUrl(
      settings,
      queryParamsSelector(state, tabIndex)
    );
    let requestOptions = merge(
      fieldsRequestOptions,
      settingsRequestOptions,
      reqOpts
    );

    if (preFetch) {
      const updatedSettings = await preFetch(settings);

      if (Object.keys(updatedSettings).length > 0) {
        const preset = getTabPreset(state, tabIndex);
        dispatch(setSettingsValue(title, preset, updatedSettings));

        const state1 = getState();
        const settings = getTabSettings(state1, tabIndex);
        const settingsRequestOptions = getRequestOptions(
          settings,
          queryParamsSelector(state1, tabIndex)
        );

        displayUrl = getDisplayUrl(
          settings,
          queryParamsSelector(state1, tabIndex)
        );
        requestOptions = merge(
          fieldsRequestOptions,
          settingsRequestOptions,
          reqOpts
        );
      }
    }

    const { preRequest, rcParams } = fieldsRequestOptions;
    let preRequestResult = null;
    if (preRequest && rcParams) {
      try {
        const { data, additionalParams} = await preRequest(rcParams, settingsRequestOptions);

        preRequestResult = additionalParams;
        requestOptions = merge(
          fieldsRequestOptions,
          settingsRequestOptions,
          {data},
          reqOpts
        );
      } catch (e) {
        // console.error(e);
        dispatch(
          NotificationActions.set({
            message:
            `Failed to get route geometry: "${e.message}"`,
            impact: 'negative',
            autoDismiss: 5,
          })
        );

        dispatch(setIsLoading(false));
        return null;
      }
      
    }
    return _fetchData(dispatch, getState, requestOptions, tabId, displayUrl, preRequestResult);
  };
}

export function fetchSettings(tabIndex, reqOpts = {}) {
  return async (dispatch, getState) => {
    dispatch(setIsLoading(true, tabIndex));
    const state = getState();
    const title = getTitle(state, tabIndex);
    const settings = getTabSettings(state, tabIndex);
    const { preFetch } = settingsPlugin.get(
      settings.type
    );

    if (preFetch) {
      const updatedSettings = await preFetch(settings);

      if (Object.keys(updatedSettings).length > 0) {
        const preset = getTabPreset(state, tabIndex);
        dispatch(setSettingsValue(title, preset, updatedSettings));

        const state1 = getState();
        const settings = getTabSettings(state1, tabIndex);
        dispatch(setIsLoading(false));
        return settings;
      }
    }
    dispatch(setIsLoading(false));
    return settings;
  };
}

function _fetchData(dispatch, getState, ro, tabId, displayUrl, preRequestResult) {
  splunkLog(
    {
      type: 'API request',
      apiUrl: displayUrl.replace(
        /apikey=.*?(&|$)/i,
        'apiKey=<apikey placeholder>&'
      ),
    },
    'info'
  );
  return Promise.race([fetch(ro), timeoutNotifier(dispatch, getState)]).then(
    (response) => {
      let tabIndex = getTabIndexById(getState(), tabId);
      if (tabIndex === undefined) {
        // Received a response for closed tab. Exiting.
        return;
      }
      if (preRequestResult) {
        // append additional result in main resquest response
        response.preRequestResult = preRequestResult;
      }
      dispatch(setRawResult(response, tabIndex));

      const state = getState();
      tabIndex = getTabIndexById(state, tabId);
      dispatch(addHistory(displayUrl, state.tabs[tabIndex], tabIndex));
    }
  );
}

export function rawRequest(url, postData, tabIndex) {
  return (dispatch, getState) => {
    const requestOptions = {
      url,
      data: postData,
      method: postData ? 'post' : 'get',
    };
    const state = getState();
    const tabId = getID(state, tabIndex);
    return _fetchData(dispatch, getState, requestOptions, tabId, url);
  };
}

export function rawRequestWithAuth(url, postData, tabIndex) {
  return (dispatch, getState) => {
    let requestOptions = {
      url,
      data: postData,
      method: postData ? 'post' : 'get',
    };
    const state = getState();
    const settings = getTabSettings(state, tabIndex);
    if (settings.type === "hereToken") {
      const { getRequestOptions } = settingsPlugin.get(
        settings.type
      );
      const settingsRequestOptions = getRequestOptions(
        settings,
        queryParamsSelector(state, tabIndex)
      );
      requestOptions = merge(
        settingsRequestOptions,
        requestOptions
      );
    }
    const tabId = getID(state, tabIndex);
    return _fetchData(dispatch, getState, requestOptions, tabId, url);
  };
}