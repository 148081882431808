import axios from 'axios';
import { latLngBounds } from 'leaflet';

export const search = async (value, appSettings, bounds) => {
  const { top, right, bottom, left } = bounds;
  const leafletBounds = latLngBounds([
    [top, right],
    [bottom, left],
  ]);
  const center = leafletBounds.getCenter();
  const params = {
    ...(appSettings.isBboxSearch ? { at: `${center.lat},${center.lng}` } : {}),
    q: value,
    limit: 10,
    lang: appSettings.searchLang || 'en',
    apiKey:
      appSettings.searchApiKey === 'DEFAULT_API_KEY'
        ? window.APIKEY
        : appSettings.searchApiKey,
  };
  const res = await axios.get(`https://geocode.search.hereapi.com/v1/geocode`, {
    params,
  });
  const { data } = res;
  return data.items.map(({ title, position, id }) => ({
    label: title,
    position: `${position.lat},${position.lng}`,
    locationId: id,
  }));
};
