import React, { useState } from 'react';
import HereMarker from 'ref-client-core/views/shared/hereMap/HereMarker';
import HoveredStationTooltip from './HoveredStationTooltip';
import { get } from 'lodash';

// const getPower = (power) => `${power / 1000} kW`;

function StationMarker({
  setResultState,
  data,
  dataIndex,
  icon,
  position,
}) {
  const [hoveredStations, setHoveredStations] = useState(null);

  const selectStation = (e) => {
    const selectedStation = data[dataIndex];
    const connectors = get(selectedStation, 'extended.evStation.connectors', []);
    const hoverData = connectors.map(connector => {return {
      numberOfConnectors: get(connector, 'chargingPoint.numberOfConnectors', 0),
      connectorType: get(connector, 'connectorType.name', 0),
      supplierName: get(connector, 'supplierName', ''),
      maxPowerLevel: get(connector, 'maxPowerLevel', '')
    }});
    setResultState({
      selectedStation,
      selectedNearBy: null
    });

    const {
      originalEvent: { x: left, y: top },
    } = e;
    setHoveredStations({ stations: hoverData, position: { left, top } });
  };

  const tooltipLeave = () => {
    setHoveredStations(null);
  };

  const marker =
    <HereMarker
      latLng={{ lat: position.lat, lng: position.lng }}
      options={{ icon }}
      onTap={selectStation}
    />;

  return (
    <>
      {marker}
      {hoveredStations && (
        <HoveredStationTooltip
          position={hoveredStations.position}
          stations={hoveredStations.stations}
          onMouseLeave={tooltipLeave}
        />
      )}
    </>
  );
}

export default StationMarker;
