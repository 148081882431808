import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './styles/apiUrlInput.scss';
import { CopyButton, Input } from '@here/ref-client-ui';
import ApiUrlTextarea from './ApiUrlTextarea';
import { isInternal } from 'state/config';
import adjustLUIStyle from '../../utils/adjustLUIStyle';

export default class ApiUrlInputContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showTextarea: false,
    };
    this.inputEl = React.createRef();
  }

  componentDidMount() {
    adjustLUIStyle(document.querySelectorAll('.rf-api-url__form lui-button'), 'lui-button');
  }
  
  getStyles = () => {
    const el = this.inputEl.current;
    if (el) {
      const inputRef = el.inputRef.current;
      const {
        bottom: elBottom,
        left,
        width,
      } = inputRef.getBoundingClientRect();
      const bottom = document.body.clientHeight - elBottom - 1;
      return { left: left - 1, bottom, width: width + 2 };
    }
    return {};
  };

  onChange = (e) => {
    const { postData, apiUrlUpdate, apiUrl } = this.props;
    
    const patternMatch = new RegExp('\\?(.*)');
    const patternReplace = new RegExp('\\b(apiKey=).*?(&|#|$)');

    const inputValue = e.target.value;
    const inputApikeyParams = new URLSearchParams(inputValue.match(patternMatch)[0]);
    const apikeyParams = new URLSearchParams(apiUrl.match(patternMatch)[0]);

    apiUrlUpdate({ 
      url: (inputApikeyParams.get('apiKey') === "DEFAULT_API_KEY" 
      ? inputValue.replace(patternReplace,`$1${apikeyParams.get('apiKey')}$2`) 
      : inputValue), 
      postData 
    });
  };

  onChangePostData = (postData) => {
    const { apiUrlUpdate, apiUrl } = this.props;
    apiUrlUpdate({ url: apiUrl, postData });
  };

  onCopy = (e) => {
    const { target } = e;
    if (target.tagName === 'TEXTAREA') {
      const startPos = target.selectionStart;
      const endPos = target.selectionEnd;
      const selectionText = target.value.substring(startPos, endPos);
      e.clipboardData.setData('text/plain', selectionText);
    } else {
      const selectionText = window.getSelection().toString();
      e.clipboardData.setData('text/plain', selectionText.replace(/\n/g, ''));
      e.preventDefault();
    }
  };

  showTextarea = () => {
    document.addEventListener('copy', this.onCopy);
    this.setState({ showTextarea: true });
  };

  hideTextarea = () => {
    document.removeEventListener('copy', this.onCopy);
    this.setState({ showTextarea: false });
  };

  render() {
    const {
      configs,
      apiUrl,
      isLoading,
      sendRequest,
      isApiUrlMultiline,
      setAppSettings,
      postData,
      setNotification,
      setTabParamsMapping,
      paramsMapping,
    } = this.props;
    const { showTextarea } = this.state;

    const [hostAndPath = '', params = ''] = apiUrl.split('?');
    const encodedParams = params
      .split('&')
      .map((param) => {
        const [key, value] = param.split(/=(.*)/);
        return `${key}=${encodeURIComponent(value)}`;
      })
      .join('&');

      
    const maskedApiUrl = apiUrl.replace(window.APIKEY, 'DEFAULT_API_KEY');
    const adaptedEncodedParams = isInternal(configs) ? encodedParams : encodedParams.replace(window.APIKEY,'YOUR_API_KEY');
    const encodedUrl = `${hostAndPath}?${adaptedEncodedParams}`;
    
    return (
      <form onSubmit={sendRequest} className="rf-api-url__form">
        <Input
          className="rf-api-url-input"
          label="API Call"
          type="text"
          value={maskedApiUrl}
          onBlur={this.onChange}
          blurOnEnter
          spellCheck="false"
          onMouseEnter={this.showTextarea}
          ref={this.inputEl}
        />
        {showTextarea && (
          <ApiUrlTextarea
            valueWithApiKey={apiUrl}
            value={maskedApiUrl}
            onChange={this.onChange}
            hideTextarea={this.hideTextarea}
            style={this.getStyles()}
            isApiUrlMultiline={isApiUrlMultiline}
            setAppSettings={setAppSettings}
            postData={postData}
            onChangePostData={this.onChangePostData}
            setNotification={setNotification}
            setTabParamsMapping={setTabParamsMapping}
            paramsMapping={paramsMapping}
          />
        )}
        <CopyButton value={encodedUrl}>
          <div className="rf-input__btn-copy rf-api-url-copy-btn" />
        </CopyButton>
        <lui-button onClick={sendRequest}>
          {isLoading ? (
            <>
              <lui-spinner /> Cancel
            </>
          ) : (
            'Send'
          )}
        </lui-button>
      </form>
    );
  }
}

ApiUrlInputContainer.propTypes = {
  apiUrlUpdate: PropTypes.func.isRequired,
  apiUrl: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sendRequest: PropTypes.func.isRequired,
  setAppSettings: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  isApiUrlMultiline: PropTypes.bool.isRequired,
  postData: PropTypes.object,
  setTabParamsMapping: PropTypes.func.isRequired,
  paramsMapping: PropTypes.bool.isRequired,
};
