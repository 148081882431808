const matrixResult = {
  title: 'Matrix Result V8',
  tabTitle: 'MatrixResult',
  notShareable: true,
  settings: {
    ApiKey: {
      type: 'apiKey',
      url: '{matrixResultUrl}',
      apiKey: process.env.REACT_APP_APIKEY,
    },
    "ApiKey 2": {
      type: 'apiKey',
      url: '{matrixResultUrl}',
      apiKey: process.env.REACT_APP_APIKEY2,
    },
  },
  inputs: [
    {
      type: 'matrixResult',
    },
    {
      type: 'input',
      options: {
        key: 'matrixResultUrl',
        label: 'Matrix Result Url',
        isPathParam: true,
      },
    },
    {
      type: 'resultFieldsStore',
    },
  ],
  map: [
    {
      type: 'matrixMarkers',
      options: {
        origins_path: 'fields.origins',
        destinations_path: 'fields.destinations',
      },
    },
    {
      type: 'matrixRoutes',
    },
    {
      type: 'contextMenu',
      options: {
        items: [
          {
            type: 'copyLatLon',
            options: {
              text: 'Copy LatLon',
            },
          },
          {
            type: 'copyAddress',
            options: {
              text: 'Copy Address',
            },
          },
        ],
      },
    },
  ],
  resultPanel: [
    {
      type: 'matrixResult',
    },
    {
      type: 'tab',
      options: {
        tabs: [
          {
            title: 'Summary',
            type: 'matrixList',
          },
          {
            title: 'Response',
            type: 'rawResponse',
          },
        ],
      },
    },
  ],
};

export default matrixResult;
