import React from 'react';
import PropTypes from 'prop-types';
import { FormRow, Tooltip, Select, Loader } from '@here/ref-client-ui';
import './selectFormView.scss';
import stringParseUrl from '../stringParseUrl';
import set from 'lodash/set';
import get from 'lodash/get';
import { getOpenapiInfo, getOpenapiUrl } from '../openapiHelpers';
import yamlLoad from '../checkboxes/yamlLoad';
import Searchable from '../../../views/shared/searchUtils/Searchable';

class SelectFormView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openapiInfo: null,
      params: [],
      isLoading: false,
    };
  }
  componentDidMount() {
    const {
      options: { openapiInfo },
      settings,
      fields: { customizations }
    } = this.props;

    if (openapiInfo) {
      const { urlPath, urlRegex, urlSuffix, keys } = openapiInfo;
      const propsUrl = get(this.props, urlPath);
      const url = getOpenapiUrl(propsUrl, urlRegex, urlSuffix);
      getOpenapiInfo(url, keys, customizations, settings).then((openapiInfo) => {
        if (!this.isUnmounted) {
          this.setState({ openapiInfo });
        }
      });
    }
    this.loadParams();
  }

  componentDidUpdate(prevProps) {
    const {
      options: { loadParams },
    } = this.props;
    if (loadParams) {
      const { urlPath } = loadParams;
      const customizations = get(this.props, 'fields.customizations');

      if (get(this.props, urlPath) !== get(prevProps, urlPath) 
        || customizations !== get(prevProps, 'fields.customizations')
        || (customizations && get(this.props, 'settings.apiKey') !== get(prevProps, 'settings.apiKey'))
        || (customizations && get(this.props, 'settings.token') !== get(prevProps, 'settings.token'))
      ) {
        this.loadParams();
      }
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  loadParams = () => {
    const {
      options: { loadParams },
    } = this.props;
    if (loadParams) {
      const { url, resultPath, urlPath, urlRegex, urlSuffix } = loadParams;

      let openapiUrl;
      if (url) {
        openapiUrl = url;
      } else {
        const propsUrl = get(this.props, urlPath, '');
        const regexp = new RegExp(urlRegex);
        const matchedUrl = propsUrl.match(regexp);
        if (matchedUrl) {
          openapiUrl = `${matchedUrl[0]}${urlSuffix}`;
        }
      }
      if (!openapiUrl) {
        return;
      }
      this.setState({ isLoading: true, params: [] });
      const { settings, fields: { customizations } } = this.props;
      yamlLoad(openapiUrl, customizations, settings).then((data) => {
        if (this.isUnmounted) {
          return;
        }
        let params = get(data, resultPath).filter(
          (value) => !value.startsWith('-')
        );
        params = params.map((value) => ({ value, label: value }));
        this.setState({ isLoading: false, params });
      });
    }
  };

  getTooltip() {
    const {
      options: { tooltip },
    } = this.props;
    let tooltipEl = null;
    const { openapiInfo } = this.state;
    if (tooltip || openapiInfo) {
      tooltipEl = (
        <div className="rf-form-view__tooltip">{tooltip || openapiInfo}</div>
      );
    }

    return tooltipEl;
  }

  onChange = (e) => {
    const {
      options: { key },
      setFields,
    } = this.props;

    setFields({ [key]: e.target.value });
  };

  render() {
    const {
      options: { label, selectOptions, key, loadParams, initValue = '', noEmptyOption },
      fields,
    } = this.props;
    const { params: stateParams, isLoading } = this.state;

    const getSelectEl = (options) => (
      <Select
        label={label}
        options={options}
        value={fields[key]||initValue}
        onChange={this.onChange}
        tooltip={this.getTooltip()}
        tooltipPlacement="right"
        addEmptyOption={!noEmptyOption}
      />
    );

    const params = loadParams ? stateParams : selectOptions;
    const selectedOption = params.find(
      (option) => option.value === fields[key]
    );
    if (!fields[key] || selectedOption) {
      return (
        <Searchable searchKey={key}>
          <FormRow>{getSelectEl(params)}</FormRow>
        </Searchable>
      );
    }

    const tooltip = `Select option "${fields[key]}" is not known by current configuration.`;
    return (
      <FormRow className="rf-select-form-view__unknown-select">
        <Tooltip tooltip={tooltip}>
          <div>
            {getSelectEl([
              ...params,
              { value: fields[key], label: fields[key] },
            ])}
          </div>
        </Tooltip>
        {isLoading && <Loader />}
      </FormRow>
    );
  }
}

SelectFormView.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  setFields: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: stringParseUrl,
  defaultState: (options) => ({
    [options.key]: options.initValue || '',
  }),
  getRequestOptions: (fields, options) => {
    const { key, isPost, type } = options;
    if (!fields[key]) return {};
    if (isPost) {
      const data = {};
      let value = fields[key];
      if (type === 'boolean') {
        if (value === 'true') {
          value = true;
        } else if (value === 'false') {
          value = false;
        }
      }
      set(data, key, value);
      return {
        method: 'post',
        data,
      };
    }
    const params = { [key]: fields[key] };
    return { params };
  },
  Component: SelectFormView,
};
