import React, { useEffect, useRef } from 'react';
import { TILE_LIBRARY_TYPES } from 'ref-client-core/state/map/tiles/constants';
import {
  useIcon,
  useNearByIcon
} from './helpers';
import HerePolyline from 'shared/hereMap/HerePolyline';
import StationMarker from './StationMarker';
import NearByMarker from './NearByMarker';
import { get } from "lodash";
import { CHARGING_STATION_TABS } from './constants';

const getPhases = (extended) => {
  const connectors = get(extended, 'evStation.connectors', []);

  let phases = '';
  const phasesMap = {
    1: 0,
    3: 0,
    5: 0
  }
  connectors.forEach((connector, i) => {
    const p = get(connector, 'chargingPoint.phases', 5);
    phasesMap[p] = p;
  })

  const phasesResults = phasesMap[1] + phasesMap[3] + phasesMap[5];
  if ( phasesResults === 1 ) {
    phases = '1';
  } else if ( phasesResults === 3 ) {
    phases = '3';
  } else if ( phasesResults === 4 ) {
    phases = '13';
  } else if ( phasesResults === 5 ) {
    phases = '';
  } else if ( phasesResults === 6 ) {
    phases = '1D';
  } else if ( phasesResults === 8 ) {
    phases = '3D';
  } else if ( phasesResults === 9 ) {
    phases = '13D';
  } 

  return phases;
}

function ChargingStations(props) {
  const {
    bounds,
    fields,
    setFields,
    setResultState,
    result,
    colorPalette,
    map
  } = props;
    const { bbox, inputType, points, searchAts } = fields;
    const {
      state: { data = [], nearBys = [], nearBy, selectedNearBy, selectedStation },
    } = result;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const markerIcons = useRef(useIcon(colorPalette));
    const nearByIcons = useRef(useNearByIcon(colorPalette));

    const searchAtsRef = useRef(searchAts);
    const style = {lineWidth: 9 };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!bbox && bounds.top) {
        const { top, right, bottom, left } = bounds;
        const topRight = [top, right].map((coord) => coord.toFixed(6));
        const bottomLeft = [bottom, left].map((coord) => coord.toFixed(6));
        setFields({ bbox: [{ geometry: [topRight, bottomLeft], type: 'bbox' }] });
      }
      searchAtsRef.current = searchAts;
    });

    const onClickRoute = ({ originalEvent: { layerX, layerY } }) => {
      const coord = map.screenToGeo(layerX, layerY)
      const { lat, lng } = coord;
      const newSearchAts = JSON.parse(searchAtsRef.current);
      newSearchAts.push({ lat: +lat.toFixed(7), lng: +lng.toFixed(7) });
      setFields({ searchAts: JSON.stringify(newSearchAts), numbers: newSearchAts.length });
    }
    
    let route = <></>;
    if (inputType === CHARGING_STATION_TABS.ALONG_THE_ROUTE) {
      route = <>
        <HerePolyline
          latLngs={JSON.parse(points)} 
          options={{style}}
          changeCursorOnHover
          onTap={onClickRoute}
        />
      </>
    }
    
    const markers = [];
    data.forEach(({ position, id, extended }, dataIndex) => {
      const isSelected =
        selectedStation &&
        id === selectedStation.id;
      const phasesIcon = getPhases(extended);
      const icon = markerIcons.current[(isSelected ? 'selectedIcon' : 'icon') + phasesIcon];
      markers.push(
        <StationMarker
          key={id}
          id={id}
          data={data}
          icon={icon}
          position={position}
          dataIndex={dataIndex}
          setResultState={setResultState}
        />
      );
    });

    const nearByMarkers = [];
    if (nearBys.length) {

    }
    nearBys.forEach(({ position, id }, dataIndex) => {
      const isSelected =
        selectedStation &&
        id === selectedNearBy;
      const icon = nearByIcons.current[(isSelected ? 'selectedIconNearBy'+dataIndex : 'iconNearBy')];
      nearByMarkers.push(
        <NearByMarker
          key={id}
          id={id}
          data={data}
          icon={icon}
          position={position}
          dataId={id}
          setResultState={setResultState}
        />
      );
    });

    return <>
        {markers}
        {nearBy && (nearByMarkers)}
        {route}
      </>;
  };

const chargingStationsMapPlugin = {
  [TILE_LIBRARY_TYPES.LEAFLET]: ChargingStations,
  [TILE_LIBRARY_TYPES.JSLA]: ChargingStations,
};

export default chargingStationsMapPlugin;
