import config from 'config/tiles';
import { TILE_TYPES } from '../../../state/map/tiles/constants';
import { isString } from 'lodash';

export const TILE_TYPE_ENGINE_MAP = {
  [TILE_TYPES.RASTER]: window.H.map.render.RenderEngine.EngineType.P2D,
  [TILE_TYPES.RASTERV3]: window.H.map.render.RenderEngine.EngineType.P2D,
  [TILE_TYPES.VECTOR]: window.H.map.render.RenderEngine.EngineType.WEBGL,
  [TILE_TYPES.VECTORHARP]: window.H.map.render.RenderEngine.EngineType.HARP,
};

export function getTileLayer(tilesData, styleUrl, showTruckTile) {
  const { type } = tilesData;
  const platform = createPlatform(tilesData);
  switch (type) {
    case TILE_TYPES.RASTER:
      return getRasterTileLayer(platform, tilesData[type]);
    case TILE_TYPES.RASTERV3:
      return getRasterTileV3Layer(platform, tilesData[type]);
    case TILE_TYPES.VECTOR:
      return getVectorTileLayer(platform, tilesData[type], styleUrl);
    case TILE_TYPES.VECTORHARP:
      return getVectorHarpTileLayer(platform, tilesData[type], showTruckTile);
    default:
      throw new Error(
        `Failed to create tile layer. Unsupported tile type: "${type}"`
      );
  }
}

function getRasterTileLayer(
  platform,
  {
    apiKey,
    appId,
    appCode,
    type,
    scheme,
    size,
    format,
    lg,
    pview,
    additionalParameters,
    url,
  }
) {
  let serviceType = config.type.find((item) => item.value === type).serviceType;
  const tileServiceOpts = { type: serviceType };
  if (!apiKey) {
    tileServiceOpts.baseUrl = new window.H.service.Url(
      'https',
      url,
      'maptile/2.1',
      {
        app_id: appId,
        app_code: appCode,
      }
    );
  }
  const mapTileService = platform.getMapTileService(tileServiceOpts);

  let additionalParametersObj = {};
  if ( pview !== '-none-' ) {
    additionalParametersObj['pview'] = pview;
  }
  if (isString(additionalParameters)) {
    additionalParameters.split('&').forEach((pair) => {
      let [key, val = ''] = pair.split('=');
      additionalParametersObj[key] = val;
    });
  } else {
    additionalParametersObj = additionalParameters;
  }
  return mapTileService.createTileLayer(type, scheme, size, format, {
    ...additionalParametersObj,
    lg,
  });
}

function getRasterTileV3Layer(
  platform,
  {
    apiKey,
    appId,
    appCode,
    type,
    style,
    ppi,
    size,
    format,
    lg,
    pview,
    projection,
    additionalParameters,
    url,
    fPois,
    fVehicleRestrictions,
    fCongestionZones,
    fEnvZones,
  }
) 
{
  const featuresparams = [];
  if (fCongestionZones && fCongestionZones !== '-none-') {
    featuresparams.push(`congestion%20zones:${fCongestionZones}`);
  }
  if (fPois && fPois !== '-none-') {
    featuresparams.push(`pois:${fPois}`);
  }
  if (fVehicleRestrictions && fVehicleRestrictions !== '-none-') {
    featuresparams.push(`vehicle_restrictions:${fVehicleRestrictions}`);
  }
  if (fEnvZones && fEnvZones !== '-none-') {
    featuresparams.push(`environmental%20zones:${fEnvZones}`);
  }

  let ppiQuery = '';
  if ( ppi === '-auto-' ) {
    ppiQuery = `&ppi=${(window.devicePixelRatio && window.devicePixelRatio > 1) ? '200' : '100'}`;
  } else if ( ppi !== '-none-' ) {
    ppiQuery = `&ppi=${ppi}`;
  }

  const params = 
  `${lg && lg !== '-none-' ? `&lg=${lg}` : ''}`+
  `${style && style !== '-none-' ? `&style=${style}` : ''}`+
  ppiQuery +
  `${featuresparams.length > 0 ? `&features=${featuresparams.join(',')}` : ''}`+
  `${size && size !== '-none-' ? `&size=${size}` : ''}`+
  `${pview && pview !== '-none-' ? `&pview=${pview}` : ''}`+
  `${additionalParameters ? `&${additionalParameters}` : ''}`+
  `&apiKey=${apiKey === 'DEFAULT_API_KEY' ? window.APIKEY : apiKey}`;
  
  var tileProvider = new window.H.map.provider.ImageTileProvider({
    getURL: function (x, y, z) {
      return `https://maps.${url}/v3/${type}/${projection}/${z}/${x}/${y}/${format}?${params.substring(1)}`;
    }
  });
  var overlayLayer = new window.H.map.layer.TileLayer(tileProvider);
  return overlayLayer;
}

export function getVectorTileLayer(
  platform,
  urlOptions,
  styles,
  serviceOptions = {}
) {
  const { url, apiKey, path } = urlOptions;
  const apikey = apiKey === 'DEFAULT_API_KEY' ? window.APIKEY : apiKey;
  const service = platform.getOMVService({
    subDomain: '',
    baseUrl: new window.H.service.Url(
      'https',
      url,
      path.replace(/^\/|\/$/g, ''),
      {
        apiKey: apikey
      }
    ),
    ...serviceOptions,
  });
  const provider = new window.H.service.omv.Provider(
    service,
    new window.H.map.Style(styles)
  );
  return new window.H.map.layer.TileLayer(provider, { max: 22 });
}

export function getVectorHarpTileLayer(
  platform,
  urlOptions,
  showTruckTile,
  serviceOptions = {}
) {
  const { url, apiKey, path, styleUrl } = urlOptions;
  const apikey = apiKey === 'DEFAULT_API_KEY' ? window.APIKEY : apiKey;
  const service = platform.getOMVService({
    subDomain: '',
    baseUrl: new window.H.service.Url(
      'https',
      url,
      path.replace(/^\/|\/$/g, ''),
      {
        apiKey: apikey
      }
    ),
    ...serviceOptions,
  });
  
  const layer = service.createLayer(
    new window.H.map.render.harp.Style(styleUrl), 
    {
      engineType: window.H.Map.EngineType.HARP
    },{
      max: 22
    });
  if (showTruckTile) {
    layer
      .getProvider()
      .getStyle()
      .setEnabledFeatures([{feature: 'vehicle restrictions'}]);
  }
  return layer;
}

export function getRasterTrafficTileLayer(platform, urlOptions) {
  const {
    url,
    path,
    imageformat,
    apiKey
  } = urlOptions;
  var tileProvider = new window.H.map.provider.ImageTileProvider({
    getURL: function (x, y, z) {
      return `https://${url}${path}${z}/${x}/${y}/${imageformat}?apiKey=${apiKey}`;
    }
  });
  var overlayLayer = new window.H.map.layer.TileLayer(tileProvider);
  return overlayLayer;
}

function createPlatform(tilesData) {
  const { type } = tilesData;
  const { apiKey, url } = tilesData[type];
  return new window.H.service.Platform({
    // We need API_KEY placeholder cause without this we receive exception in case of empty apikey
    apiKey:
      (apiKey === 'DEFAULT_API_KEY' ? window.APIKEY : apiKey) || 'API_KEY',
    baseUrl: window.H.service.Url.parse(`https://${url}`),
  });
}


export function setJslaPoliticalView(map, pview) {
  const mapStyle = map
    .getBaseLayer()
    .getProvider()
    .getStyle();

  var changeListener = (evt) => {
    if (mapStyle.getState() === window.H.map.Style.State.READY) {
      mapStyle.removeEventListener('change', changeListener);
      mapStyle.setProperty('global.political_view', pview );
    }
  };
  mapStyle.addEventListener('change', changeListener);
}

export function setJslaHarpPoliticalView(map, pview) {
  const provider = map
    .getBaseLayer()
    .getProvider();
    
  provider.setPoliticalView(pview);
}