import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as TrafficOverlaysActions from 'state/map/trafficOverlays/actions';

import LayerToggle from './LayerToggle';
import VectorTrafficOverlaysForm from './VectorTrafficOverlaysForm';
import { get } from 'lodash';
import { TILE_TYPES } from 'state/map/tiles/constants';

class TrafficOverlays extends Component {
  state = { formExpanded: false };

  onToggle = () => {
    const { tilesData, setNotification, setTilesType } = this.props;
    const { isInternal, libraryType, type } = tilesData;
    let isActive = get(this.props, 'data.isActive');
    if ( !isInternal && !isActive && libraryType === 'jsla' && type !== 'vector') {
      setNotification({
        children: (
          <div>
            Following tiles type was automatically enabled:
            <ul>
              <li>Vector Tiles</li>
            </ul>
          </div>
        ),
        impact: 'significant',
      });
      setTilesType(TILE_TYPES.VECTORHARP);
    } 
    this.props.toggle(!isActive);
  };

  onToggleForm = () => {
    this.setState((state) => ({
      formExpanded: !state.formExpanded,
    }));
  };

  onChange = (type, prop, event) => {
    this.props.setData(type, { [prop]: event.target.value });
  };

  renderForm(isActive) {
    const { data, tilesData } = this.props;

    let tilesType  = data.type;
    let { type, isInternal, libraryType } = tilesData
    if ( isInternal && type !== 'vector' && libraryType === 'jsla') {
      if (type === 'vectorHARP') {
        tilesType = type;
      } else
        tilesType = 'raster';
    }

    return (
      <VectorTrafficOverlaysForm
        onChange={(...args) => this.onChange(tilesType, ...args)}
        data={data[tilesType]}
        isActive={isActive}
        onToggle={this.onToggle}
        onBlur={this.onToggleForm}
      />
    );
  }

  render() {
    const { formExpanded } = this.state;
    let isActive = get(this.props, 'data.isActive');
    let tooltipText = formExpanded ? undefined : 'Traffic Overlays';

    return (
      <div>
        <div className="rf-map__traffic-overlays">
          <LayerToggle
            active={isActive}
            onChange={this.onToggleForm}
            label="TO"
            tooltipText={tooltipText}
          />
        </div>
        {formExpanded && this.renderForm(isActive)}
      </div>
    );
  }
}

TrafficOverlays.propTypes = {
  setData: PropTypes.func.isRequired,
  setType: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  tilesData: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    data: state.map.trafficOverlays,
    tilesData: state.map.tiles,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setData: bindActionCreators(TrafficOverlaysActions.setData, dispatch),
    setType: bindActionCreators(TrafficOverlaysActions.setType, dispatch),
    toggle: bindActionCreators(TrafficOverlaysActions.toggle, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrafficOverlays);
