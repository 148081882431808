import Tangram from 'tangram';
import { MapLayer, withLeaflet } from 'react-leaflet';
import { getTrafficLayerStyles } from '../constants';
import { isEqual } from 'lodash';

const getUrl = (props) => {
  const { url, path, apiKey } = props.data.vector;
  return `https://${url}/${path.replace(
    /^\/|\/$/,
    ''
  )}{z}/{x}/{y}/omv?apiKey=${apiKey}`;
};

class TrafficLayer extends MapLayer {
  createLeafletElement(props) {
    const scene = getTrafficLayerStyles();
    // scene.layers.traffic_flow.filter = "function() {\n  console.log(feature);return true;\n}\n";
    scene.sources.traffic.url = getUrl(props);
    scene.sources.traffic.type = 'MVT';
    this.layer = Tangram.leafletLayer({ scene });
    this.layer.setZIndex(2);
    this.interval = setInterval(() => {
      this.layer.scene.load(scene);
    }, props.data.vector.refreshInterval * 1000);

    return this.layer;
  }

  updateLeafletElement(prevProps, props) {
    if (!isEqual(prevProps.data.vector, props.data.vector)) {
      clearInterval(this.interval);
      const scene = getTrafficLayerStyles();
      scene.sources.traffic.url = getUrl(props);
      scene.sources.traffic.type = 'MVT';
      this.layer.scene.load(scene);

      this.interval = setInterval(() => {
        this.layer.scene.load(scene);
      }, props.data.vector.refreshInterval * 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.layer.remove();
  }
}

export default withLeaflet(TrafficLayer);
