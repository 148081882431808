import RefClient from 'ref-client-core';
import chargingStationsInputPlugin from './chargingStationsInputPlugin';
import chargingStationsMapPlugin from './chargingStationsMapPlugin';
import ChargingStationsResult from './chargingStationsResultPlugin';
import ChargingStationsResultNearby from './chargingStationsNearbysResultPlugin';

RefClient.formViewsPlugin.register(
  'chargingStations',
  chargingStationsInputPlugin
);
RefClient.resultPanelPlugin.register(
  'chargingStations',
  ChargingStationsResult
);
RefClient.resultPanelPlugin.register(
  'chargingStationsNearby',
  ChargingStationsResultNearby
);
RefClient.mapPlugin.register('chargingStations', chargingStationsMapPlugin);
