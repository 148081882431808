const intermodal = {
  title: 'Intermodal Routing V8',
  tabTitle: 'Intermodal',
  settings: {
    'Api Key': {
      type: 'apiKey',
      url: 'https://intermodal.router.hereapi.com/v8/routes',
      apiKey: process.env.REACT_APP_APIKEY,
    },
    'Api Key 2': {
      type: 'apiKey',
      url: 'https://intermodal.router.hereapi.com/v8/routes',
      apiKey: process.env.REACT_APP_APIKEY2,
    },
  },
  inputs: [
    {
      type: 'input',
      options: {
        label: 'Origin',
        placeholder: 'Origin',
        key: 'origin',
        removeSpaces: true,
        validation: [
          {
            type: 'regExp',
            value:
              '^[-+]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?),[-+]?(180(\\.0+)?|((1[0-7]\\d)|([1-9]?\\d))(\\.\\d+)?)(;placeName=.+)?$',
          },
        ],
      },
    },
    {
      type: 'input',
      options: {
        label: 'Destination',
        placeholder: 'Destination',
        key: 'destination',
        removeSpaces: true,
        validation: [
          {
            type: 'regExp',
            value:
              '^[-+]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?),[-+]?(180(\\.0+)?|((1[0-7]\\d)|([1-9]?\\d))(\\.\\d+)?)(;placeName=.+)?$',
          },
        ],
      },
    },
    {
      type: 'intermodalVia',
      options: {
        label: 'Via',
        key: 'via',
        places: [{ value: 'parkingLot', label: 'Parking Lot' }],
        strategies: [
          { value: 'diverseChoices', label: 'Diverse Choices' },
          { value: 'parkLate', label: 'Park Late' },
          { value: 'parkEarly', label: 'Park Early' },
        ],
      },
    },
    {
      type: 'routeTime',
      options: {
        key: 'departureTime',
        label: 'Departure Time',
        cssClasses: 'rf-grey-box',
        isLocalSupported: true,
        supportAny: false,
        supportArrival: false,
      },
    },
    {
      type: 'input',
      options: {
        key: 'alternatives',
        label: 'Alternatives',
        inputType: 'number',
        validation: [
          { type: 'min', value: 0 },
          { type: 'max', value: 6 },
        ],
      },
    },
    {
      type: 'input',
      options: {
        key: 'changes',
        label: 'Changes',
        inputType: 'number',
        validation: [
          { type: 'min', value: 0 },
          { type: 'max', value: 6 },
        ],
      },
    },
    {
      type: 'input',
      options: {
        key: 'pedestrian[speed]',
        label: 'Pedestrian speed',
        inputType: 'number',
        validation: [
          { type: 'min', value: 0.5 },
          { type: 'max', value: 2 },
        ],
      },
    },
    {
      type: 'input',
      options: {
        key: 'pedestrian[maxDistance]',
        label: 'Max. walking distance',
        inputType: 'number',
        validation: [
          { type: 'min', value: 0 },
          { type: 'max', value: 6000 },
        ],
      },
    },
    {
      type: 'input',
      options: {
        key: 'bicycle[minDistance]',
        label: 'Min. cycling distance',
        inputType: 'number',
        validation: [
          { type: 'min', value: 1 },
          { type: 'max', value: 5000 },
        ],
      },
    },
    {
      type: 'input',
      options: {
        key: 'bicycle[maxDistance]',
        label: 'Max. cycling distance',
        inputType: 'number',
        validation: [
          { type: 'min', value: 1 },
          { type: 'max', value: 10000 },
        ],
      },
    },
    {
      type: 'input',
      options: {
        key: 'kickScooter[minDistance]',
        label: 'Min. kick scooter travel distance',
        inputType: 'number',
        validation: [
          { type: 'min', value: 1 },
          { type: 'max', value: 5000 },
        ],
      },
    },
    {
      type: 'input',
      options: {
        key: 'kickScooter[maxDistance]',
        label: 'Max. kick scooter travel distance',
        inputType: 'number',
        validation: [
          { type: 'min', value: 1 },
          { type: 'max', value: 10000 },
        ],
      },
    },
    {
      type: 'checkboxes',
      options: {
        key: 'transit[modes]',
        label: 'Transit modes',
        isExpanded: true,
        supportExcludes: true,
        initState: 'highSpeedTrain,intercityTrain,interRegionalTrain,regionalTrain,cityTrain,bus,ferry,subway,lightRail,privateBus,inclined,aerial,busRapid,monorail,flight,spaceship',
        cssClasses: 'rf-grey-box',
        params: [
          { value: 'highSpeedTrain', label: 'High Speed Train' },
          { value: 'intercityTrain', label: 'Intercity Train' },
          { value: 'interRegionalTrain', label: 'Inter Regional Train' },
          { value: 'regionalTrain', label: 'Regional Train' },
          { value: 'cityTrain', label: 'City Train' },
          { value: 'bus', label: 'Bus' },
          { value: 'ferry', label: 'Ferry' },
          { value: 'subway', label: 'Subway' },
          { value: 'lightRail', label: 'Light Rail' },
          { value: 'privateBus', label: 'Private Bus' },
          { value: 'inclined', label: 'Inclined' },
          { value: 'aerial', label: 'Aerial' },
          { value: 'busRapid', label: 'Bus Rapid' },
          { value: 'monorail', label: 'Mono Rail' },
          { value: 'flight', label: 'Flight' },
          { value: 'spaceship', label: 'Spaceship' },
        ],
      },
    },
    {
      type: 'checkboxes',
      options: {
        key: 'transit[enable]',
        label: 'Enable transit in',
        isExpanded: true,
        initState: 'routeHead,routeTail,entireRoute',
        params: [
          { value: 'routeHead', label: 'Route Head' },
          { value: 'routeTail', label: 'Route Tail' },
          { value: 'entireRoute', label: 'Entire Route' },
        ],
      },
    },
    {
      type: 'checkboxes',
      options: {
        key: 'vehicle[modes]',
        label: 'Vehicle modes',
        isExpanded: true,
        supportExcludes: true,
        initState: '',
        params: [
          { value: 'car', label: 'Car' },
          { value: 'bicycle', label: 'Bicycle' },
          { value: 'kickScooter', label: 'Kick Scooter' }
        ],
      },
    },
    {
      type: 'checkboxes',
      options: {
        key: 'vehicle[enable]',
        label: 'Enable vehicle in',
        isExpanded: true,
        initState: '',
        cssClasses: 'rf-grey-box',
        params: [
          { value: 'routeHead', label: 'Route Head' },
          { value: 'routeTail', label: 'Route Tail' },
          { value: 'entireRoute', label: 'Entire Route' },
        ],
      },
    },
    {
      type: 'checkboxes',
      options: {
        key: 'taxi[modes]',
        label: 'Taxi modes',
        isExpanded: true,
        supportExcludes: true,
        initState: 'car',
        params: [{ value: 'car', label: 'Car' }],
      },
    },
    {
      type: 'checkboxes',
      options: {
        key: 'taxi[enable]',
        label: 'Enable taxi in',
        isExpanded: true,
        initState: 'routeHead,routeTail,entireRoute',
        cssClasses: 'rf-grey-box',
        params: [
          { value: 'routeHead', label: 'Route Head' },
          { value: 'routeTail', label: 'Route Tail' },
          { value: 'entireRoute', label: 'Entire Route' },
        ],
      },
    },
    {
      type: 'checkboxes',
      options: {
        key: 'rented[modes]',
        label: 'Rented modes',
        isExpanded: true,
        supportExcludes: true,
        initState: 'car,bicycle,kickScooter',
        params: [
          { value: 'car', label: 'Car' },
          { value: 'bicycle', label: 'Bicycle' },
          { value: 'kickScooter', label: 'Kick Scooter' },
        ],
      },
    },
    {
      type: 'checkboxes',
      options: {
        key: 'rented[enable]',
        label: 'Enable rented in',
        isExpanded: true,
        initState: 'routeHead,routeTail,entireRoute',
        cssClasses: 'rf-grey-box',
        params: [
          { value: 'routeHead', label: 'Route Head' },
          { value: 'routeTail', label: 'Route Tail' },
          { value: 'entireRoute', label: 'Entire Route' },
        ],
      },
    },
    {
      type: 'checkboxes',
      options: {
        key: 'return',
        label: 'Return',
        isExpanded: true,
        initState:
          'intermediate,fares,polyline,actions,travelSummary,incidents',
        params: [
          { value: 'intermediate', label: 'Intermediate' },
          { value: 'fares', label: 'Fares' },
          { value: 'polyline', label: 'Polyline' },
          { value: 'actions', label: 'Actions' },
          { value: 'travelSummary', label: 'Travel Summary' },
          { value: 'incidents', label: 'Incidents' },
        ],
      },
    },
    {
      type: 'extraParams',
    },
    {
      type: 'language',
      options: {
        key: 'lang',
        label: 'Language',
      },
    },
    {
      type: 'select',
      options: {
        label: 'Units',
        key: 'units',
        selectOptions: [
          { label: 'Metric', value: 'metric' },
          { label: 'Imperial/US', value: 'imperial' },
        ],
      },
    },
  ],
  map: [
    {
      type: 'contextMenu',
      options: {
        items: [
          {
            type: 'setFields',
            options: {
              text: 'Route from',
              key: 'origin',
              conditions: [
                {
                  type: 'equals',
                  key: 'paramsMapping',
                  value: true,
                },
              ],
            },
          },
          {
            type: 'setFields',
            options: {
              text: 'Route to',
              key: 'destination',
              conditions: [
                {
                  type: 'equals',
                  key: 'paramsMapping',
                  value: true,
                },
              ],
            },
          },
          {
            type: 'copyLatLon',
            options: {
              text: 'Copy LatLon',
            },
          },
          {
            type: 'copyAddress',
            options: {
              text: 'Copy Address',
            },
          },
        ],
      },
    },
    {
      type: 'marker',
      options: {
        text: 'A',
        coordsPath: 'fields.origin',
      },
    },
    {
      type: 'marker',
      options: {
        text: 'B',
        coordsPath: 'fields.destination',
      },
    },
    {
      type: 'ptRoutes',
      options: {
        routesArrayPath: 'result.raw.data.routes',
        sectionsPath: 'sections',
        routePath: 'polyline',
        sectionTypePath: 'type',
        transportColorPath: 'transport.color',
        format: 'flexPolyline',
        selectedPath: 'result.state.currentRoute',
        highlightedPath: 'result.state.highlightedRoute',
      },
    },
    {
      type: 'ptMarkers',
      options: {
        routesArrayPath: 'result.raw.data.routes',
        selectedPath: 'result.state.currentRoute',
      },
    },
  ],
  resultPanel: [
    {
      type: 'routeSelector',
    },
    {
      type: 'tab',
      options: {
        tabs: [
          {
            title: 'Summary',
            type: 'olsPtSummary',
          },
          {
            title: 'Instructions',
            type: 'olsPtManeuvers',
            options: {
              key: 'selectedManeuver',
              formatter: 'actionsToManeuvers',
            },
          },
          {
            title: 'Response',
            type: 'rawResponse',
          },
        ],
      },
    },
  ],
  autoRefresh: {
    conditions: [
      {
        type: 'required',
        options: {
          path: 'fields.origin',
        },
      },
      {
        type: 'required',
        options: {
          path: 'fields.destination',
        },
      },
    ],
  },
};

export default intermodal;
